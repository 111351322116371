import { post, get } from "@/utils/axios";

export function getAllRolesFromNodeList(nodeGuids) {
  return post("/role/GetAllRolesFromNodeList", {
    guidList: nodeGuids
  });
}

export function getAllPermissionsFromNodeList(nodeGuids) {
  return post("/role/GetAllPermissionsFromNodeList", {
    guidList: nodeGuids
  });
}

export function getRolePermissionFromUser(userGuid) {
  return get(`/role/GetRolePermissionsFromUser/${userGuid}`);
}

export async function createOneRole(
  nodeGuids,
  { roleDispName, roleDescription, permissionGuidList }
) {
  let response = await post("/role/CreateOneUserRole", {
    nodeGuidList: nodeGuids,
    dispName: roleDispName,
    description: roleDescription,
    permissionGuids: permissionGuidList
  });
  return response.guid;
}

export async function updateOneRoleInfo({
  roleGuid,
  roleDispName,
  roleDescription,
  permissionGuidList
}) {
  let response = await post("/role/UpdateOneUserRoleInfo", {
    guid: roleGuid,
    dispName: roleDispName,
    description: roleDescription,
    permissionGuids: permissionGuidList
  });
  return response.guid;
}

export async function deleteOneRole(roleGuid) {
  let response = await post("/role/DeleteOneUserRole", {
    guid: roleGuid
  });
  return response.guid;
}

export function fetchAllSuperPermissions() {
  return get("/managenoderole/GetAllSuperPermissions");
}

export async function addOnePermissionToNode(nodeGuid, permGuid) {
  let response = await post(
    `/managenoderole/AddOnePermissionToClientNode/${nodeGuid}`,
    {
      guid: permGuid
    }
  );
  return response.guid;
}

export async function deleteOnePermissionFromNode(permGuid) {
  let response = await post(
    "/managenoderole/DeleteOnePermissionFromClientNode",
    {
      guid: permGuid
    }
  );
  return response.guid;
}
