import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import permission from "./modules/permission";
import sca from "./modules/sca";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    permission,
    sca
  }
});
