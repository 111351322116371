import { staticRoutes, dynamicRoutes } from "@/router/appRoutes";

function $_routeAlreadyExist(route, existingRoutes) {
  if (existingRoutes && existingRoutes.length) {
    return existingRoutes.findIndex(er => er.name === route.name) > -1;
  }
  return false;
}

function $_hasPermission(route, permissionNames) {
  if (route.meta && route.meta.perms) {
    let permMatched = permissionNames.some(
      perm => route.meta.perms.indexOf(perm) > -1
    );
    return permMatched;
  } else {
    // 没有 meta.perms 说明没有 permission 限制
    return true;
  }
}

function $_getAccessableRoutes(routes, permissionNames, existingRoutes) {
  let accRoutes = [];
  for (let route of routes) {
    if (
      !$_routeAlreadyExist(route, existingRoutes) &&
      $_hasPermission(route, permissionNames)
    ) {
      // 注意不能改到 routes 本身
      let newRoute = { ...route };
      if (route.children && route.children.length) {
        newRoute.children = $_getAccessableRoutes(
          route.children,
          permissionNames,
          existingRoutes
        );
      }
      accRoutes.push(newRoute);
    }
  }
  return accRoutes;
}

export default {
  namespaced: true,
  state: {
    userRoutes: [],
    dynamicUserRoutes: []
  },

  getters: {
    userRoutes(state) {
      if (!state.userRoutes || !state.userRoutes.length) {
        return staticRoutes;
      }
      return state.userRoutes;
    },
    dynamicUserRoutes(state) {
      if (!state.dynamicUserRoutes || !state.dynamicUserRoutes.length) {
        return [];
      }
      return state.dynamicUserRoutes;
    }
  },

  actions: {
    generateUserRoutes({ commit }, { existingRoutes, permissionNames }) {
      return new Promise(resolve => {
        // 所有的有权限的 route
        let accessableRoutes = $_getAccessableRoutes(
          dynamicRoutes,
          permissionNames
        );
        // 去除已存在的 route，为了防止 addRouter 的重复添加
        let nonExistAccessableRoutes = $_getAccessableRoutes(
          dynamicRoutes,
          permissionNames,
          existingRoutes
        );
        commit("SET_USER_ROUTES", staticRoutes.concat(accessableRoutes));
        commit("SET_DYNAMIC_USER_ROUTES", nonExistAccessableRoutes);
        resolve();
      });
    },
    clearUserRoutes({ commit }) {
      commit("SET_USER_ROUTES", staticRoutes);
      commit("SET_DYNAMIC_USER_ROUTES", []);
    }
  },

  mutations: {
    SET_USER_ROUTES(state, userRoutes) {
      state.userRoutes = userRoutes;
    },
    SET_DYNAMIC_USER_ROUTES(state, dynamicUserRoutes) {
      state.dynamicUserRoutes = dynamicUserRoutes;
    }
  }
};
