<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss">
@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?rqxfu0");
  src: url("./assets/fonts/icomoon.eot?rqxfu0#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?rqxfu0") format("truetype"),
    url("./assets/fonts/icomoon.woff?rqxfu0") format("woff"),
    url("./assets/fonts/icomoon.svg?rqxfu0#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-font {
  font-family: "icomoon" !important;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
