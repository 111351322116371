import { permissioNames } from "@/utils/constants/user";

export const notFountRoute = {
  path: "*",
  name: "nopage",
  meta: {
    type: "nopage",
    title: "对不起，您没有权限进行这个操作"
  },
  component: () => import(/* webpackChunkName: "nopage" */ "@/views/NoPage.vue")
};

export const staticRoutes = [
  {
    path: "/",
    name: "login",
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/noreg",
    name: "noreg",
    props: true,
    meta: {
      type: "noreg",
      title: "未注册"
    },
    component: () => import(/* webpackChunkName: "noreg" */ "@/views/NoReg.vue")
  },
  {
    path: "/scalogin",
    name: "scalogin",
    props: route => ({ mode: route.query.mode, scacode: route.query.cd }),
    meta: {
      type: "scalogin",
      title: "测量中心登录"
    },
    component: () =>
      import(/* webpackChunkName: "scalogin" */ "@/views/ScaLogin.vue")
  },
  {
    path: "/sca",
    name: "sca",
    meta: {
      type: "sca"
    },
    component: () =>
      import(/* webpackChunkName: "sca" */ "@/views/sca/ScaApp.vue"),
    children: [
      {
        path: "infoinput",
        name: "scainfoinput",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scainfoinput" */ "@/views/sca/ScaInfoInput.vue"
          )
      },
      {
        path: "scastepper",
        name: "scastepper",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scastepper" */ "@/views/sca/ScaStepper.vue"
          )
      },
      {
        path: "scaselect",
        name: "scaselect",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scaselect" */ "@/views/sca/ScaSelect.vue"
          )
      },
      {
        path: "scatestertext",
        name: "scatestertext",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scatestertext" */ "@/views/sca/ScaTesterText.vue"
          )
      },
      {
        path: "scareport",
        name: "scareport",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scareport" */ "@/views/sca/ScaReport.vue"
          )
      }
    ]
  }
];

export const dynamicRoutes = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      type: "admin"
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/Admin.vue"),
    children: [
      {
        path: "report",
        name: "report",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-text-box-search",
            title: "案例管理"
          },
          perms: [permissioNames.case_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "report" */
            "@/views/admin/AdminCaseReport.vue"
          )
      },
      {
        path: "testperson",
        name: "testperson",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-account-multiple",
            title: "被试人员管理"
          },
          perms: [permissioNames.person_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "testperson" */
            "@/views/admin/AdminTestPerson.vue"
          )
      },
      {
        path: "node",
        name: "confignode",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-layers",
            title: "测评单位管理"
          },
          perms: [permissioNames.node_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "confignode" */
            "@/views/admin/AdminConfigNode.vue"
          )
      },
      {
        path: "config",
        name: "configbase",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-pencil-box-multiple",
            title: "测评"
          },
          perms: [
            permissioNames.group_sca_all.value,
            permissioNames.open_sca_all.value
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "configbase" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "grouplbgroup",
            name: "grouplbgroup",
            props: () => ({ type: "group" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-calendar-clock",
                title: "团体测评"
              },
              perms: [permissioNames.group_sca_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "grouplbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          },
          {
            path: "openlbgroup",
            name: "openlbgroup",
            props: () => ({ type: "open" }),
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-earth",
                title: "开放测评"
              },
              perms: [permissioNames.open_sca_all.value]
            },
            component: () =>
              import(
                /* webpackChunkName: "openlbgroup" */
                "@/views/admin/AdminLbGroup.vue"
              )
          }
        ]
      },
      {
        path: "lbmanage",
        name: "lbmanage",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-folder-cog",
            title: "量表"
          },
          perms: [permissioNames.lb_doc_read.value, permissioNames.lb_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "lbmanage" */
            "@/views/admin/AdminLbManage.vue"
          )
      },
      {
        path: "settings",
        name: "settings",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-cog",
            title: "设置"
          },
          perms: [permissioNames.admin_user_all.value]
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            "@/views/admin/AdminSettings.vue"
          )
      }
    ]
  }
];
